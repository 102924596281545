import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/InfoSection.css';
import image1 from '..//images/heart.webp'; // Update the path
import image2 from '..//images/image1.webp'; // Update the path

const InfoSection = () => (
  <div className="info-section py-5">
    <Container>
      <Row className="info-row mb-4">
        <Col md={12} lg={6}>
          <Card className="h-100 info-2">
            <Card.Body>
              <Card.Title>Welcome to <br></br>Imagine Seniorcare Inc!</Card.Title>
              <Card.Text>
                At Imagine Senior Care we are dedicated to providing compassionate and personalized care for your loved ones. 
                Our team of fully trained professionals is committed to ensuring that every client receives the highest level 
                of support, assistance and resources. As senior advocates we understand the unique needs of seniors and their 
                families. Our services are designed to enhance the quality of life for our clients, offering peace of mind to 
                their families. We are devoted to providing a full range of assistance to seniors and families all throughout 
                the Bay Area.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} lg={6}>
          <Card className="h-100 info-img">
            <Card.Img variant="top" src={image1} alt="Our Team" />
          </Card>
        </Col>
      </Row>
      <Row className="info-row">
        <Col md={12} lg={6} className="order-lg-1 order-md-2 order-2">
          <Card className="h-100 info-img1">
            <Card.Img variant="top" src={image2} alt="Our Services" />
          </Card>
        </Col>
        <Col md={12} lg={6} className="order-lg-2 order-md-1 order-1">
          <Card className="h-100 info-1">
            <Card.Body>
              <Card.Title>Our Mission</Card.Title>
              <Card.Text>
                At Imagine Senior Care, our mission is to deliver compassionate support and homecare services to our seniors with 
                specialized needs. Offering professional and individualized care to seniors at the comfort of the place they call 
                home.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
);

export default InfoSection;
