import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  Spinner,
  InputGroup,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CreateInvoice from './CreateInvoice';
import { collection, getDocs, getDoc, doc, updateDoc, addDoc, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { faFilePdf, faCalendarAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import '../styles/ClientTimesheets.css';
import "react-toastify/dist/ReactToastify.css";

const ClientTimesheets = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [filteredTimesheets, setFilteredTimesheets] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientFilter, setClientFilter] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalHoursWorked, setTotalHoursWorked] = useState('0.00');
  const [totalCharges, setTotalCharges] = useState('0.00');
  const [totalEmployeeSalary, setTotalEmployeeSalary] = useState('0.00');
  const [netIncome, setNetIncome] = useState('0.00');
  const [editableValues, setEditableValues] = useState({});
  const [invoices, setInvoices] = useState([]); 
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  // Fetch clients from Firestore
  const fetchClients = async () => {
    try {
        const clientSnapshot = await getDocs(collection(db, 'clients'));
        const clientsData = clientSnapshot.docs.map((doc) => {
            const client = doc.data();

            // Ensure individualDates is stored as objects, not just strings
            const updatedDates = (client.individualDates || []).map((entry) =>
                typeof entry === 'string'
                    ? { date: entry, totalHours: 0, hourlyRate: 0, employeeHourlyRate: 0, totalCharges: 0 }
                    : entry
            );

            return {
                id: doc.id,
                ...client,
                individualDates: updatedDates,
            };
        });

        console.log("Fetched Clients with Dates:", clientsData);
        setClients(clientsData);

        // Now ensure timesheets exist for each client
        await ensureTimesheetsForClients(clientsData);
    } catch (error) {
        console.error('Error fetching clients:', error);
    }
  };

  const ensureTimesheetsForClients = async (clientsData) => {
    try {
        for (const client of clientsData) {
            if (!client.stayStartDate || !client.stayEndDate) {
                console.warn(`Skipping client ${client.name} due to missing stay dates.`);
                continue;
            }

            const stayStartDate = new Date(client.stayStartDate);
            const stayEndDate = new Date(client.stayEndDate);

            for (let date = new Date(stayStartDate); date <= stayEndDate; date.setDate(date.getDate() + 1)) {
                const formattedDate = date.toISOString().split("T")[0];

                // Check if timesheet already exists for this client and date
                const timesheetQuery = query(
                    collection(db, "clientTimesheets"),
                    where("clientId", "==", client.id),
                    where("logDate", "==", formattedDate)
                );
                const timesheetSnapshot = await getDocs(timesheetQuery);

                if (timesheetSnapshot.empty) {
                    // Create new timesheet entry if it doesn't exist
                    await addDoc(collection(db, "clientTimesheets"), {
                        clientId: client.id,
                        logDate: formattedDate,
                        totalHours: 0,
                        hourlyRate: client.hourlyRate || 0,
                        employeeHourlyRate: 0,
                        totalCharges: 0
                    });

                    console.log(`Created missing timesheet for client ${client.name} on ${formattedDate}`);
                }
            }
        }

        // Fetch updated timesheets after ensuring they exist
        fetchTimesheetsWithClients();
    } catch (error) {
        console.error("Error ensuring timesheets for clients:", error);
    }
  };



  // Fetch timesheets and join with client data
  const fetchTimesheetsWithClients = async () => {
    setIsLoading(true);

    try {
        const timesheetSnapshot = await getDocs(collection(db, "clientTimesheets"));
        let timesheetsData = timesheetSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        console.log("Fetched Timesheets:", timesheetsData);

        if (clients.length === 0) {
            console.warn("Clients not loaded yet.");
            return;
        }

        // Merge clients with timesheets
        let enrichedTimesheets = timesheetsData.map(timesheet => {
            const client = clients.find(c => c.id === timesheet.clientId);
            return {
                ...timesheet,
                clientName: client?.name || "Unknown Client",
                assignedEmployee: client?.assignedEmployee || "Unassigned",
                hourlyRate: timesheet.hourlyRate ?? client?.hourlyRate ?? 0,
                employeeHourlyRate: timesheet.employeeHourlyRate ?? 0,
                totalCharges: (timesheet.totalHours * timesheet.hourlyRate).toFixed(2),
                totalEmployeeSalary: (timesheet.totalHours * timesheet.employeeHourlyRate).toFixed(2),
                netIncome: ((timesheet.totalHours * timesheet.hourlyRate) - (timesheet.totalHours * timesheet.employeeHourlyRate)).toFixed(2)
            };
        });

        // 🔥 Sort timesheets by logDate
        enrichedTimesheets.sort((a, b) => new Date(a.logDate) - new Date(b.logDate));

        console.log("Enriched Timesheets:", enrichedTimesheets);

        localStorage.setItem("timesheets", JSON.stringify(enrichedTimesheets));
        setTimesheets(enrichedTimesheets);
    } catch (error) {
        console.error("Error fetching timesheets:", error);
    } finally {
        setIsLoading(false);
    }
  };



  useEffect(() => {
    fetchClients(); // Fetch clients first
  }, []);

  useEffect(() => {
    if (clients.length > 0) { // Fetch timesheets only after clients are loaded
        fetchTimesheetsWithClients();
    }
  }, [clients]);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
          const invoiceSnapshot = await getDocs(collection(db, "invoices"));
          const invoiceData = invoiceSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
          }));
          setInvoices(invoiceData);  // Ensure this is correctly updating state
      } catch (error) {
          console.error("Error fetching invoices:", error);
      }
    };

    fetchInvoices();
  }, []);


  useEffect(() => {
    const storedTimesheets = localStorage.getItem("timesheets");
    if (storedTimesheets) {
        setTimesheets(JSON.parse(storedTimesheets));
    } else if (clients.length > 0) {
        fetchTimesheetsWithClients();
    }
  }, [clients]);

  
  useEffect(() => {
    const storedTimesheets = localStorage.getItem('timesheets');
    if (storedTimesheets) {
      setTimesheets(JSON.parse(storedTimesheets));
    } else if (clients.length > 0) {
      fetchTimesheetsWithClients();
    }
  }, [clients]);
  
  useEffect(() => {
    let filtered = [...timesheets];
  
    if (clientFilter) {
      filtered = filtered.filter((ts) =>
        ts.clientName.toLowerCase().includes(clientFilter.toLowerCase())
      );
    }
  
    let startDate = dateRange[0];
    let endDate = dateRange[1];
  
    // 🔹 Default: Show last 7 days if no filter is applied
    if (!startDate && !endDate) {
      endDate = new Date();
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 7); // Default to last 7 days
    }
  
    filtered = filtered.filter((ts) => {
      const logDate = new Date(ts.logDate); // Ensure logDate is a Date object
      logDate.setUTCHours(0, 0, 0, 0); // Normalize to UTC midnight
  
      // 🔹 Normalize start and end dates
      const normalizedStartDate = new Date(startDate);
      normalizedStartDate.setUTCHours(0, 0, 0, 0);
  
      const normalizedEndDate = new Date(endDate);
      normalizedEndDate.setUTCHours(23, 59, 59, 999); // ✅ Ensures full day inclusion
  
      console.log(`Checking: ${logDate.toISOString()} against ${normalizedStartDate.toISOString()} - ${normalizedEndDate.toISOString()}`);
  
      return logDate >= normalizedStartDate && logDate <= normalizedEndDate;
    });
  
    console.log("Filtered Timesheets (Including Full End Date):", filtered);
    setFilteredTimesheets(filtered);
  }, [timesheets, clientFilter, dateRange]);  
  
  useEffect(() => {
    if (timesheets && timesheets.length > 0) {
      // Compute total hours from timesheets
      const computedTotalHours = timesheets.reduce((sum, entry) => sum + (entry.totalHours || 0), 0);
      setTotalHours(computedTotalHours);
  
      // Compute hourly rate (assuming averaging rates if different)
      const totalRates = timesheets.reduce((sum, entry) => sum + (entry.hourlyRate || 0), 0);
      const computedHourlyRate = timesheets.length > 0 ? totalRates / timesheets.length : 0;
      setHourlyRate(computedHourlyRate);
    }
  }, [timesheets]); // Run when timesheets change
  
  useEffect(() => {
    if (filteredTimesheets.length > 0) {
      const totalHours = filteredTimesheets.reduce((sum, ts) => 
        sum + (parseFloat(editableValues[`${ts.id}-totalHours`] ?? ts.totalHours) || 0), 0);
  
      const totalCharges = filteredTimesheets.reduce((sum, ts) => 
        sum + ((parseFloat(editableValues[`${ts.id}-totalHours`] ?? ts.totalHours) || 0) * 
               (parseFloat(editableValues[`${ts.id}-hourlyRate`] ?? ts.hourlyRate) || 0)), 0);
  
      const totalEmployeeSalary = filteredTimesheets.reduce((sum, ts) => 
        sum + ((parseFloat(editableValues[`${ts.id}-totalHours`] ?? ts.totalHours) || 0) * 
               (parseFloat(editableValues[`${ts.id}-employeeHourlyRate`] ?? ts.employeeHourlyRate) || 0)), 0);
  
      const netIncome = totalCharges - totalEmployeeSalary;
  
      setTotalHoursWorked(totalHours.toFixed(2));
      setTotalCharges(totalCharges.toFixed(2));
      setTotalEmployeeSalary(totalEmployeeSalary.toFixed(2));
      setNetIncome(netIncome.toFixed(2));
    } else {
      setTotalHoursWorked('0.00');
      setTotalCharges('0.00');
      setTotalEmployeeSalary('0.00');
      setNetIncome('0.00');
    }
  }, [filteredTimesheets, editableValues]);
  

  // Filter timesheets by client and date range
  useEffect(() => {
    let filtered = [...timesheets];
  
    if (clientFilter) {
      filtered = filtered.filter((ts) =>
        ts.clientName.toLowerCase().includes(clientFilter.toLowerCase())
      );
    }
  
    if (dateRange[0] && dateRange[1]) {
      filtered = filtered.filter((ts) => {
        const logDate = new Date(ts.logDate); // Ensure logDate is a Date object
        logDate.setUTCHours(0, 0, 0, 0); // Normalize to UTC midnight
    
        // Normalize start and end dates
        const startDate = new Date(dateRange[0]);
        startDate.setUTCHours(0, 0, 0, 0);
    
        const endDate = new Date(dateRange[1]);
        endDate.setUTCHours(23, 59, 59, 999); // Ensure full day inclusion
    
        console.log(`Checking Log Date: ${logDate.toISOString()} against Range: ${startDate.toISOString()} - ${endDate.toISOString()}`);
    
        return logDate >= startDate && logDate <= endDate;
      });
    }
    
  
    console.log("Filtered Timesheets:", filtered);
    setFilteredTimesheets(filtered);
  }, [timesheets, clientFilter, dateRange]);
  
  
  const handleLocalUpdate = (id, field, value) => {
    setEditableValues((prev) => ({
      ...prev,
      [`${id}-${field}`]: value, // Store per field & row uniquely
    }));
  };
  
  const handleInvoiceSuccess = (invoiceNumber) => {
    toast.success(`Invoice #${invoiceNumber} created successfully! ✅`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  
    setShowCreateInvoiceModal(false); // Close modal after success
  };
  
  

  const handleFirestoreUpdate = async (id, field) => {
    if (!id) {
        console.error("Invalid document ID:", id);
        return;
    }

    const value = editableValues[`${id}-${field}`];
    if (value === undefined) return; // Skip if no change

    const numericValue = parseFloat(value) || 0;
    const docRef = doc(db, "clientTimesheets", id);

    try {
        let updateData = { [field]: numericValue };

        if (field === "totalHours" || field === "hourlyRate" || field === "employeeHourlyRate") {
            const timesheet = timesheets.find(ts => ts.id === id);
            if (timesheet) {
                updateData.totalCharges = (numericValue * (timesheet.hourlyRate || 0)).toFixed(2);
                updateData.totalEmployeeSalary = (numericValue * (timesheet.employeeHourlyRate || 0)).toFixed(2);
                updateData.netIncome = (updateData.totalCharges - updateData.totalEmployeeSalary).toFixed(2);
            }
        }

        // Remove undefined values
        Object.keys(updateData).forEach(key => {
            if (updateData[key] === undefined) delete updateData[key];
        });

        await updateDoc(docRef, updateData);

        console.log(`Firestore Update Success: ${field} -> ${numericValue}`);

        toast.success(`${field.replace(/([A-Z])/g, ' $1')} updated successfully!`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        // ✅ **Update Local State without Fetching Everything Again**
        setTimesheets((prevTimesheets) =>
            prevTimesheets.map(ts =>
                ts.id === id
                    ? { ...ts, ...updateData } // ✅ Update only the modified timesheet
                    : ts
            )
        );

        // ✅ **Also update filteredTimesheets to prevent UI lag**
        setFilteredTimesheets((prevFiltered) =>
            prevFiltered.map(ts =>
                ts.id === id
                    ? { ...ts, ...updateData } // ✅ Update only the modified timesheet
                    : ts
            )
        );

        // ✅ **Clear local input values after saving**
        setEditableValues(prev => {
            const newValues = { ...prev };
            delete newValues[`${id}-${field}`];
            return newValues;
        });

    } catch (error) {
        console.error(`Error updating Firestore for ${field}:`, error);
        toast.error(`Failed to update ${field}.`);
    }
  };


  const handleCreateInvoice = (clientName) => {
    if (!clientName) {
        console.log("Please select a client before creating an invoice.");
        toast.error("Please select a client before creating an invoice.");
        return;
    }

    const selectedClientData = clients.find(client => client.name === clientName);
    
    if (!selectedClientData) {
        toast.error("Client data not found.");
        return;
    }

    const clientTimesheets = filteredTimesheets
        .filter(ts => ts.clientName === clientName)
        .map(ts => ({
            date: ts.logDate ? new Date(ts.logDate).toISOString().slice(0, 10) : "N/A",
            totalHours: ts.totalHours || 0,
            hourlyRate: ts.hourlyRate || 0,
            totalCharges: ts.totalCharges || 0
        }));

    if (clientTimesheets.length === 0) {
        toast.error("No timesheets found for this client.");
        console.error("🚨 No timesheets found for:", clientName);
        return;
    }

    const totalInvoiceAmount = clientTimesheets.reduce((sum, ts) => sum + parseFloat(ts.totalCharges || 0), 0);

    // ✅ Set the selected client and open the modal **without saving in Firestore yet**
    setSelectedClient({
        ...selectedClientData,
        totalCharges: totalInvoiceAmount,
        totalHours: clientTimesheets.reduce((sum, ts) => sum + (parseFloat(ts.totalHours) || 0), 0),
        timesheets: clientTimesheets,
    });

    setShowCreateInvoiceModal(true);
  };

  
  const clearFilters = () => {
    setClientFilter('');
    setDateRange([null, null]);
  };


  const renderFilters = () => (
    <Row className="align-items-center mb-4">
      <Col xs={12} sm={6} md={3} className="mb-2 mb-md-0">
        <InputGroup>
          <InputGroup.Text className="rounded-start">
            <FontAwesomeIcon icon={faUser} />
          </InputGroup.Text>
          <Form.Select
            value={clientFilter}
            onChange={(e) => setClientFilter(e.target.value)}
            className="filter-select rounded-end"
          >
            <option value="">All Clients</option>
            {clients.map((client) => (
              <option key={client.id} value={client.name}>
                {client.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
      </Col>

      <Col xs={12} sm={6} md={3} className="mb-2 mb-md-0">
        <InputGroup>
          <InputGroup.Text className="rounded-start">
            <FontAwesomeIcon icon={faCalendarAlt} />
          </InputGroup.Text>
          <DatePicker
            selected={dateRange[0]}
            onChange={(startDate) => setDateRange([startDate, dateRange[1]])}
            selectsStart
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            className="form-control rounded-end"
            placeholderText="Start Date"
          />
        </InputGroup>
      </Col>

      <Col xs={12} sm={6} md={3} className="mb-2 mb-md-0">
        <InputGroup>
          <InputGroup.Text className="rounded-start">
            <FontAwesomeIcon icon={faCalendarAlt} />
          </InputGroup.Text>
          <DatePicker
            selected={dateRange[1]}
            onChange={(endDate) => setDateRange([dateRange[0], endDate])}
            selectsEnd
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            className="form-control rounded-end"
            placeholderText="End Date"
          />
        </InputGroup>
      </Col>

      <Col xs={12} sm={6} md={3} className="mb-2 mb-md-0">
        <Button
          variant="secondary"
          onClick={clearFilters}
          className="ms-2 clear-filters-btn rounded"
        >
          Clear Filters
        </Button>
      </Col>
    </Row>
  );

  return (
    
    <Container fluid className="timecard-container">
      <Row className="align-items-center mb-3">
        <Col>
          <h1 className="timecard-title">Client Timesheets</h1>
        </Col>
      </Row>

      <hr className="divider" />

      <div className="filters-and-download">
        {renderFilters()}

        <Row className="mb-2">
          <Col xs={12} sm={12} md="auto">
            <div className="d-flex flex-wrap gap-2">
              <Button
                className="download-btn"
                onClick={() => handleCreateInvoice(clientFilter)}
              >
                <FontAwesomeIcon icon={faFilePdf} /> Create Invoice
              </Button>
              <Button onClick={clearFilters} className="download-btn">
                Clear Filters
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ width: "12%" }}>Client Name</th>
                  <th style={{ width: "13%" }}>Assigned Employee</th>
                  <th style={{ width: "8%" }}>Date</th>
                  <th style={{ width: "8%" }}>Total Hours</th>
                  <th style={{ width: "12%" }}>Client Hourly Rate</th>
                  <th style={{ width: "13%" }}>Employee Hourly Rate</th>
                  <th style={{ width: "14%" }}>Total Employee Salary</th>
                  <th style={{ width: "9%" }}>Total Charges</th>
                  <th style={{ width: "9%" }}>Net Income</th>
                </tr>
              </thead>
              <tbody>
              {filteredTimesheets.map((ts, index) => {
                const totalHours = editableValues[`${ts.id}-totalHours`] ?? ts.totalHours ?? 0;
                const clientHourlyRate = editableValues[`${ts.id}-hourlyRate`] ?? ts.hourlyRate ?? 0;
                const employeeHourlyRate = editableValues[`${ts.id}-employeeHourlyRate`] ?? ts.employeeHourlyRate ?? 0;

                const totalEmployeeSalary = (totalHours || 0) * (employeeHourlyRate || 0);
                const totalCharges = (totalHours || 0) * (clientHourlyRate || 0);
                const netIncome = totalCharges - totalEmployeeSalary;

                  return (
                    <tr key={`${ts.clientId}-${ts.logDate}`}>
                      <td>{ts.clientName || '-'}</td>
                      <td>{ts.assignedEmployee || 'Unassigned'}</td>
                      <td>{ts.logDate || '-'}</td>

                      {/* Editable Total Hours */}
                      <td>
                        <input
                          type="number"
                          value={editableValues[`${ts.id}-totalHours`] ?? ts.totalHours ?? ''}
                          onChange={(e) => handleLocalUpdate(ts.id, 'totalHours', e.target.value)}
                          onBlur={() => handleFirestoreUpdate(ts.id, 'totalHours')}
                          className="form-control"
                        />
                      </td>

                      {/* Editable Client Hourly Rate */}
                      <td>
                        <input
                          type="number"
                          value={editableValues[`${ts.id}-hourlyRate`] ?? ts.hourlyRate ?? ''}
                          onChange={(e) => handleLocalUpdate(ts.id, 'hourlyRate', e.target.value)}
                          onBlur={() => handleFirestoreUpdate(ts.id, 'hourlyRate')}
                          className="form-control"
                        />
                      </td>

                      {/* Editable Employee Hourly Rate */}
                      <td>
                        <input
                          type="number"
                          value={editableValues[`${ts.id}-employeeHourlyRate`] ?? ts.employeeHourlyRate ?? ''}
                          onChange={(e) => handleLocalUpdate(ts.id, 'employeeHourlyRate', e.target.value)}
                          onBlur={() => handleFirestoreUpdate(ts.id, 'employeeHourlyRate')}
                          className="form-control"
                        />
                      </td>

                      {/* Calculated Fields */}
                      <td>${totalEmployeeSalary.toFixed(2)}</td>
                      <td>${totalCharges.toFixed(2)}</td>
                      <td>${netIncome.toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3" style={{ fontWeight: 'bold', textAlign: 'right' }}>Totals:</td>
                  <td>{totalHoursWorked} hrs</td>
                  <td></td>
                  <td></td>
                  <td>${totalEmployeeSalary}</td>
                  <td>${totalCharges}</td>
                  <td>${netIncome}</td>
                </tr>
              </tfoot>
            </Table>
          )}
        </Col>
      </Row>
      {showCreateInvoiceModal && selectedClient && (
          <CreateInvoice 
              show={showCreateInvoiceModal}
              onClose={() => setShowCreateInvoiceModal(false)}
              onInvoiceSuccess={handleInvoiceSuccess} // ✅ Pass the function
              clientName={selectedClient?.name ?? "Unknown Client"} 
              clientAddress={selectedClient?.address ?? "N/A"}
              stayDuration={{
                  start: selectedClient?.stayStart 
                      ? new Date(selectedClient.stayStart).toISOString().slice(0, 10) 
                      : '',
                  end: selectedClient?.stayEnd 
                      ? new Date(selectedClient.stayEnd).toISOString().slice(0, 10) 
                      : '',
                  dates: Array.isArray(selectedClient?.timesheets) ? selectedClient.timesheets : [], // ✅ Ensure an array
              }}     
              invoiceAmount={selectedClient?.totalCharges ?? 0} // ✅ Ensure valid number
              hourlyRate={selectedClient?.hourlyRate ?? 0} // ✅ Ensure valid number
              totalHours={selectedClient?.totalHours ?? 0} // ✅ Ensure valid number
              timesheets={Array.isArray(selectedClient?.timesheets) ? selectedClient.timesheets : []} // ✅ Ensure an array
          />
      )}
    </Container>
  );
};
export default ClientTimesheets;
